export var DiscographyDataSet = [
    {
        'DataType': 'discography',
		'discographyID': 'HeroesofaWesternNight',
		'imageURL':'/media/discography/HeroesofaWesternNightAlbumArtwork-min.JPG',
		'WebsiteData': {
			'link':'https://leadfinder.app/',
		},
		'Externals': {
			'Listen on Spotify': 'https://open.spotify.com/track/3XNAxQAhUXDd4tMSsvcysG?si=d7ada59fe4264cc9',
            'Listen on Apple Music': 'https://music.apple.com/us/album/heroes-of-a-western-night-feat-cody-sherman/1787530273?i=1787530274',
		},
		'NavigationStyle': {
			'primaryColor': 'rgb(137,68,54, 0.7)',
			'secondaryColor': '#ffffff'
		}, 
		
		'ContentPageData': {
			'background': {
				'imageURL': '/media/discography/HeroesofaWesternNightAlbumArtwork-min.JPG',
				'color': 'rgb(164,134,100)'
			},
			'board': {
				'title': 'Heroes of a Western Night',
				'position': '',
				'type': '',
				'brief': '...tales of love and sweet romance',
				'story': `
					 An autobiographical love letter, written in conclusion to an important chapter of my life. I wanted to paint my experience of love and heartbreak against the backdrop of modern Western America. This was one of my very first original songs that I wrote. Upon completion, and for the first time, I felt a very genuine and intimate sense of satisfaction in the lyricism. Looking back, I can see the prematurity in my melodic choices. I was so excited to simply write something decent that I didnt consider taking any wild chances with the structure of the tune. Even so, I still love to listen back to this tune and let the memories of a past life run through my mind.
				
				`,
				'deliverables': ['Lyricist, producer, guitarist, pianist, vocalist '],
				'styles': {
					'buttonColor': 'rgba(0,0,0,0.3)',
					'buttonArrowColor': 'rgb(255,255,255)',
					'headerColor': '#ffffff',
					'backgroundColor': 'rgba(137,68,54,0.95)',
					'positionColor': '#ffffff',
					'titleColor': '#ffffff',
					'viewApplicationColor': '#ffffff',
					'typeColor':  '#ffffff',
					'briefColor': '#ffffff',
					'storyColor': '#ffffff',
					'skillColor': '#ffffff',
					'externalsColor': '#ffffff'
				}
				
			}
		}
	},
    {
		'DataType': 'discography',
		'discographyID': 'PuzzlePieceBlues',
		'imageURL':'/media/discography/puzzlepiecebluesalbumartwork-min.jpg',
		'WebsiteData': {
			'link':'https://leadfinder.app/',
		},
		'Externals': {
			'Listen on Spotify': 'https://open.spotify.com/track/1bjNOoaL01hRd1Iuad4hFK?si=8d614890262e4c37',
            'Listen on Apple Music': 'https://music.apple.com/us/album/puzzle-piece-blues-voice-memo/1776892538?i=1776892539',
		},
		'NavigationStyle': {
			'primaryColor': 'rgb(190,174,113, 0.7)',
			'secondaryColor': '#ffffff'
		}, 
		
		'ContentPageData': {
			'background': {
				'imageURL': '/media/discography/puzzlepiecebluesalbumartwork-min.jpg',
				'color': 'rgb(82,108,99)'
			},
			'board': {
				'title': 'Puzzle Piece Blues',
				'position': '',
				'type': '',
				'brief': 'Red, Orange, Yellow, Blue...',
				'story': `
					Puzzle Piece Blues is a personal favorite of mine. It was the first time that I managed to get my thumb to pick a decent blues pattern while I sang. Little accomplishemnts like these can mean the world especially after periods of long creative droughts. At the time, I was living in Indonesia on the island of Lombok. While I was there, I came across a couragesouly gentle soul. Her presence was entirely reflective. Without concious effort, she inspired me to understand my own heart more deeply. As I spent more time with her, I realized how much I was discovering myself. I was reflecting on hidden pieces of my heart that had been either been covered in dust or stashed in a a back closet for far too long. I wrote a number of songs during this period(Moonshine, Puzzle Piece Blues, Safe Place, Slowly Slowly, America). When writing this particular tune, the questions I asked myself were as follows... Why have I always felt like I was missing a piece of myself? Am I able to love myself completely? If so, how would that change my life, my relationships, and my spirit. 
				`,
				'deliverables': ['Lyricist, producer, guitarist, pianist, vocalist '],
				'styles': {
					'buttonColor': 'rgba(190,174,113,0.8)',
					'buttonArrowColor': 'rgb(255,255,255)',
					'headerColor': '#ffffff',
					'backgroundColor': 'rgba(84,113,89,0.95)',
					'positionColor': '#ffffff',
					'titleColor': '#ffffff',
					'viewApplicationColor': '#ffffff',
					'typeColor':  '#ffffff',
					'briefColor': '#ffffff',
					'storyColor': '#ffffff',
					'skillColor': '#ffffff',
					'externalsColor': '#ffffff'
				}
				
			}
		}
		
		
	},
    {
		'DataType': 'discography',
		'discographyID': 'MoonShine',
		'imageURL':'/media/discography/MoonShineAlbumArtwork-min.png',
		'WebsiteData': {
			'link':'https://leadfinder.app/',
		},
		'Externals': {
			
		},
		'NavigationStyle': {
			'primaryColor': 'rgb(81,75,107, 0.7)',
			'secondaryColor': '#ffffff'
		}, 
		
		'ContentPageData': {
			'background': {
				'imageURL': '/media/discography/MoonShineAlbumArtwork-min.png',
				'color': 'rgb(25,27,33)'
			},
			'board': {
				'title': 'Moonshine',
				'position': '',
				'type': '',
				'brief': 'You and I are a match made in heaven, quite a strange place for the devil to be selling',
				'story': `
				My grandparents met in the back of an old police station wagon in the mountains of North Carolina. But probably not for the reason that you'd expect. 
				My Grandfather was a preacher. As was his father. And I've got a feeling that line of work runs even further up the family tree than I know. It was an important job back then, especially in those rural areas where sometimes your faith was all that got you through the day. But there were some folk who would've rather seen that the gospel stay off of the mountains entirely. Moonshine bootleggers. For anyone who's been lucky enough to not know her, shes an alcoholic substance that sits around 97 proof and has been known to turn men blind(that is, if it's not prepared appropriately). In any case, these bootleggers weren't happy with the good word making its way to their neck of the woods on account of it being bad advertising for the product. Thus, my grandparents needed police escort to ensure they arrived safely to church. 
				So I wrote this tune about one of those Moonshine bootleggers in the mountains of North Carolina 
				“You and I, were a match made in heaven, quite a strange place for the devil to be selling”
				
				`,
				'deliverables': ['Lyricist, producer, guitarist, pianist, vocalist '],
				'styles': {
					'buttonColor': 'rgba(81,75,107,0.3)',
					'buttonArrowColor': 'rgb(255,255,255)',
					'headerColor': '#ffffff',
					'backgroundColor': 'rgba(25,27,33,0.95)',
					'positionColor': '#ffffff',
					'titleColor': '#ffffff',
					'viewApplicationColor': '#ffffff',
					'typeColor':  '#ffffff',
					'briefColor': '#ffffff',
					'storyColor': '#ffffff',
					'skillColor': '#ffffff',
					'externalsColor': '#ffffff'
				}
				
			}
		}
	},
    {
		'DataType': 'discography',
		'discographyID': 'TimeMachine',
		'imageURL':'/media/discography/timemachine-min.jpeg',
		'WebsiteData': {
			'link':'https://leadfinder.app/',
		},
		'Externals': {
			'Listen on Spotify': 'https://open.spotify.com/track/4zlK83knNxftFoN47ANx0R?si=2efce23a47cd461d',
            'Listen on Apple Music': 'https://music.apple.com/us/album/time-machine-feat-andy-citrin/1739658234?i=1739658235'
		},
		'NavigationStyle': {
			'primaryColor': 'rgb(113,101,192, 0.7)',
			'secondaryColor': '#ffffff'
		}, 
		
		'ContentPageData': {
			'background': {
				'imageURL': '/media/discography/timemachine-min.jpeg',
				'color': 'rgb(113,101,192)'
			},
			'board': {
				'title': 'Time Machine',
				'position': '',
				'type': '',
				'brief': '...If I could have one super power?',
				'story': `
                   Its an age old question that has most likely been around since the intersection of human imagination and language. I've considered the obvious choices such as acquiring the ability to soar through the skies freely, or vanishing to a different part of the world with a single thought. I have an inclination that, overtime, these activities would become as mundane and boring as boarding a place. At one point, even hopping on a bus ride to the next city over was a miraculous feat for the human experience and is something no one bats an eye at anymore. Since I was young I have always fascinated over the concept of time travel. I wondered what it would feel like to witness dinosaurs roam the Pangean plain. And watch the cataclysm that took their presence and left the Earth so abandoned and lonely. Or to scoure through endless pages in the halls of the Library of Alexandria before she was left to ash. How would it feel to watch Jimi Hendrixs play his first live show. No, what would it feel like to hear Jimi Hendrix play his very first chord on a guitar! Endless adventure
				
				`,
				'deliverables': ['Lyricist, producer, guitarist, pianist, vocalist '],
				'styles': {
					'buttonColor': 'rgba(113,101,192,0.8)',
					'buttonArrowColor': 'rgb(255,255,255)',
					'headerColor': '#ffffff',
					'backgroundColor': 'rgba(158,180,106,0.95)',
					'positionColor': '#ffffff',
					'titleColor': '#ffffff',
					'viewApplicationColor': '#ffffff',
					'typeColor':  '#ffffff',
					'briefColor': '#ffffff',
					'storyColor': '#ffffff',
					'skillColor': '#ffffff',
					'externalsColor': '#ffffff'
				}
				
			}
		}
	},
    {
		'DataType': 'discography',
		'discographyID': 'SafePlace',
		'imageURL':'/media/discography/SafePlaceAlbumArtwork-min.png',
		'WebsiteData': {
			'link':'https://leadfinder.app/',
		},
		'Externals': {
			
		},
		'NavigationStyle': {
			'primaryColor': 'rgba(228,132,162,0.7)',
			'secondaryColor': '#ffffff'
		}, 
		
		'ContentPageData': {
			'background': {
				'imageURL': '/media/discography/SafePlaceAlbumArtwork-min.png',
				'color': 'rgb(249,213,118)'
			},
			'board': {
				'title': 'Safe Place',
				'position': '',
				'type': '',
				'brief': 'Love aint a dangerous place',
				'story': `
					I havent yet written the bio for this song yet. Because I cant figure out what to say about it. It needs more time...
				`,
				'deliverables': ['Lyricist, producer, guitarist, pianist, vocalist '],
				'styles': {
					'buttonColor': 'rgba(0,0,0,0.3)',
					'buttonArrowColor': 'rgb(255,255,255)',
					'headerColor': '#ffffff',
					'backgroundColor': 'rgba(228,132,162,0.95)',
					'positionColor': '#ffffff',
					'titleColor': '#ffffff',
					'viewApplicationColor': '#ffffff',
					'typeColor':  '#ffffff',
					'briefColor': '#ffffff',
					'storyColor': '#ffffff',
					'skillColor': '#ffffff',
					'externalsColor': '#ffffff'
				}
				
			}
		}
	},
	
]


