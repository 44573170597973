import React from 'react'
import BlockArrowWidget from 'components/Widgets/Buttons/BlockArrow.widget';
import ShuffleWidget from 'components/Widgets/Shuffle/Shuffle.widget';
import { withRouter} from 'react-router-dom';
import {BlogDataSet} from 'data/BlogDataSet';

import ParallaxBackground from '../../Widgets/ParallaxBackground';
const $ = window.$;

const parallaxConfiguration = {
	'backgroundImageUrl': 'media/photography/tayrona-rock.jpg',
	'overlayItemImageUrls': ['']
}
class BlogListPage extends React.Component {
	
	constructor(props){
		super(props)
		this.state = {
			
		}
		
	}

	/******Life Cycle Functions********/
	componentDidMount() {
		$('.shuffle-wrapper').css({'overflow':'scroll'})	
	}
	/***********************************/
	
	/******Toggle Functions********/
	slideHome () {
		//todo: move this up a higher level =
		if ($('.transitioning').length > 0) {
			return;
		}
		this.props.history.push({
		  pathname: '/home',
		  originPath: '/blog',
		  targetPath: '/home'
		})
	}
	onBlogClick (blogData) {
		if ($('.transitioning').length > 0) {
   			return;
   		}
		this.props.history.push('/blog/' + blogData.BlogID);
	}
	/***********************************/

	
	render() {
		//<SearchBarWidget />
		return (
			<div className='page-bloglist' >
				<ParallaxBackground parallaxConfiguration={parallaxConfiguration}/>
				<BlockArrowWidget  clickHandler={() => this.slideHome()}/>
				<h1 className='explore-header'>Explore</h1>
				<div className='shuffle-wrapper'>
					<ShuffleWidget clickHandler={this.onBlogClick.bind(this)} isBlogDataSet={true} items={BlogDataSet}/>
				</div>
				
			</div>
		);
	}
}

export default withRouter(BlogListPage)


