import React from 'react'
import ProjectContentBoard from './projectContentBoard';
import {ProjectDataSet} from 'data/data';
import {DiscographyDataSet} from 'data/data'
import {NavigationButtonHelper} from 'lib/Helpers/NavigationButton';
import MoveIt from 'lib/MoveIt/MoveIt';
import BlockArrowWidget from 'components/Widgets/Buttons/BlockArrow.widget';
const $ = window.$;


class ProjectContentPage extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			contentData: this.retrieveProjectData()
		}
		
	}

	componentDidMount () {
		MoveIt().initialize();

		NavigationButtonHelper.setColor(this.state.contentData.NavigationStyle);
	}

	retrieveProjectData () {
		if (this.props.discographyID) {
			return DiscographyDataSet.filter((discographyData) => {
				return discographyData.discographyID === this.props.discographyID
			}).pop();
		} else {
			return ProjectDataSet.filter((projectData) => {
				return projectData.ProjectID === this.props.projectID
			}).pop();
		}

		
	}


	buildContentPage (type) {
		var contentPageStyle =  {
			backgroundColor: this.state.contentData.ContentPageData.background.color 
		}
		var contentPageBackgroundStyle = {
			backgroundImage: 'url(' + this.state.contentData.ContentPageData.background.imageURL + ')'
		}


		return (
			<div className='page-contentpage' style={contentPageStyle}>
				<div className='background box' style={contentPageBackgroundStyle} data-scroll-speed="9"></div>
				<ProjectContentBoard data={this.state.contentData} type={type}/>
			</div>
		);
	}


	
	
	render() {
		var contentPage;
		if (this.props.projectID) { //Build project page
			contentPage = this.buildContentPage ('project');
		} else if(this.props.discographyID) {  //Build discography page
			contentPage = this.buildContentPage ('discography');
		}

		return (
			<div style={{'width':'100%','height':'100%','position':'absolute'}}>
				{contentPage}
			</div>
		)
	}
}

export default ProjectContentPage