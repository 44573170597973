import React from 'react'
import {NavigationButtonHelper} from 'lib/Helpers/NavigationButton';
import ParallaxBackground from '../../Widgets/ParallaxBackground';
import { withRouter, Link} from 'react-router-dom';


import SocialWidget from 'components/Widgets/SocialWidget';
import _ from 'lodash';

class SubjectLandingPage extends React.Component {
    componentDidMount () {

    }

    
	render() {
        const { parallaxConfiguration, linkDataSet } = this.props;
        
		return (
			<div className="page-subjectlanding">
                <ParallaxBackground parallaxConfiguration={parallaxConfiguration}/>

                <div className='overlay'>
                    <SocialWidget/>
                    <a href="mailto:dailenspencer@gmail.com" className='bottom-email'>dailenspencer@gmail.com</a>
                    <div className='bottom-copyright'>© Copyright 2017</div>
                </div>
                <div className="dark-overlay"></div>
                <div className='subjectlanding-button-container'>
                    {
                        linkDataSet.map((linkData) => {
                            if (linkData.isExternalLink) {
                                return (
                                    <a href={`${linkData.link}`} target="_blank" className='subjectlanding-button'>{linkData.title}</a>
                                )
                            }
                            else if (linkData.isContactButton) {
                                return (
                                    <a href="mailto:dailenspencer@gmail.com" className='subjectlanding-button'>{linkData.title}</a>
                                )
                            } else {
                                return (                                
                                    <Link to={linkData.link} style={{ textDecoration: 'none' }}>
                                        <div className='subjectlanding-button'>{linkData.title}</div>
                                    </Link>   
                                )
                            }
                            
                        })
                    }
                </div>
                
				
			</div>
		);
	}
}

export default withRouter(SubjectLandingPage);