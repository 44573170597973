export function determineIntroReactRouterAnimationType (originPath, targetPath) {
	//home and blog
	if (originPath === '/home' && targetPath === '/blog') {
		return 'slideInFromBottom';
	}
	if (originPath === '/blog' && targetPath === '/home') {
		return 'slideInFromTop';
	}

	if (originPath === '/home' && targetPath === '/subscribe') {
		return 'slideInFromTop';
	}
	if (originPath === '/subscribe' && targetPath === '/home') {
		return 'slideInFromBottom';
	}
	

	return 'default'
}

export function determineExitReactRouterAnimationType (originPath, targetPath) {
	
	//home and blog
	if (originPath === '/home' && targetPath === '/blog') {
		return 'slideOutToTop';
	}
	if (originPath === '/blog' && targetPath === '/home') {
		return 'slideOutToBottom';
	}

	if (originPath === '/home' && targetPath === '/subscribe') {
		return 'slideOutToBottom';
	}
	if (originPath === '/subscribe' && targetPath === '/home') {
		return 'slideOutToTop';
	}

	return 'default'

}