import React from 'react'
import BlockArrowWidget from 'components/Widgets/Buttons/BlockArrow.widget';
import ShuffleWidget from 'components/Widgets/Shuffle/Shuffle.widget';
import { withRouter} from 'react-router-dom';
import {DiscographyDataSet} from 'data/DiscographyDataSet';
import {NavigationButtonHelper} from 'lib/Helpers/NavigationButton';
import {deviceIsMobile} from 'lib/Helpers/JqueryHelpers';

import ParallaxBackground from '../../Widgets/ParallaxBackground';
const $ = window.$;


class DiscographyListPage extends React.Component {
	
	constructor(props){
		super(props)
		this.state = {
			
		}
		
	}

	/******Life Cycle Functions********/
	componentDidMount() {
		$('.shuffle-wrapper').css({'overflow':'scroll'})	
		NavigationButtonHelper.setColor({
			'primaryColor': 'rgba(0,0,0,0.0)',
			'secondaryColor': 'white'
		});

		
	}
	/***********************************/
	
	/******Toggle Functions********/
	slideBack () {
		//todo: move this up a higher level =
		if ($('.transitioning').length > 0) {
			return;
		}
		this.props.history.goBack()
	}
	onDiscographyClick (discographyData) {

		if ($('.transitioning').length > 0) {
   			return;
   		}
		NavigationButtonHelper.showBackButton();
		this.props.history.push('discography/' + discographyData.discographyID);
	}
	/***********************************/


	buildDiscographyItems () {
		console.log('DiscographyDataSet',DiscographyDataSet)
		return DiscographyDataSet.map((item, index) => {
			console.log('buildPictureItem',item)

			return this.buildPictureItem(item)
		})
	}

	buildPictureItem (item) {
		var backgroundImageStyle = {
			'backgroundImage': 'url(' + item.imageURL + ')',
			'transform': deviceIsMobile() ? 'scale(1.0)' : ''
		}

		return (
			
			<div className={'picture-item ' + item.styleType} onClick={() => this.onDiscographyClick(item)}>
		 		<div className="aspect aspect--16x16">
			    	<div className='picture-item-bg' style={backgroundImageStyle}>
			    		<div className='overlay'>
				    		
				    	</div>
			    	</div>			    	
			    </div>
		    </div>
		)
	}
	
	render() {
		//<SearchBarWidget />
		const { parallaxConfiguration  } = this.props;
		return (
			<div className='page-discographyList' >
				<ParallaxBackground parallaxConfiguration={parallaxConfiguration}/>
				<BlockArrowWidget  clickHandler={() => this.slideBack()}/>
				<div className='overlay'>
					<a href="mailto:dailenspencer@gmail.com" className='bottom-email'>dailenspencer@gmail.com</a>
                    <div className='bottom-copyright'>© Copyright 2017</div>
                </div>
                <div className="dark-overlay"></div>
				
				<h1 className='discography-header'>Discography</h1>
				<div className='discographyList-container'>
					{this.buildDiscographyItems()}
				</div>
				{/* <div className='shuffle-wrapper'>
					<ShuffleWidget items={DiscographyDataSet} isBlogDataSet={false} clickHandler={this.onDiscographyClick.bind(this)}/>
				</div> 
				 */}
			</div>
		);
	}
}

export default withRouter(DiscographyListPage)