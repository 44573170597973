import React from 'react'
import { withRouter } from 'react-router-dom'
import {NavigationButtonHelper} from 'lib/Helpers/NavigationButton';
import LIB from 'lib/LIB';
const $ = window.$;

class NavigationButton extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			
		}
		this.handleNavigationButtonAnimation = this.handleNavigationButtonAnimation.bind(this);
	}

	shouldComponentUpdate(nextProps, nextState) {
		return false;
	}
	
	handleNavigationClick () {
		//todo: move this up a higher level =
		if ($('.transitioning').length > 0) {
			return;
		}

		var APP_VERSION = LIB.COOKIE.get('FORCE_APP_VERSION');
		var MUSIC_VERSION = window.location.href.includes('dailenspencermusic');
		var onMusicHomePage = window.location.pathname === "/";

		NavigationButtonHelper.setColor({primaryColor:'rgba(0,0,0,0.0)'});

		if (MUSIC_VERSION) {
			this.handleNavigationButtonAnimation()

			if (!onMusicHomePage) {
				this.props.history.push('/music');
				NavigationButtonHelper.collapseBackButton();
			} 
			
			return;
		}	

	
		//NOTE: if we are on the professional version of the site, and already on the home route, then dont try and push a new route
		if (APP_VERSION == 'prof' && this.context.router.history.location.pathname == '/') {
			return;
		} else if (APP_VERSION == 'prof') {
			this.props.history.push('/')
		} else {
			this.props.history.push('/navigation')
		}
		
		this.handleNavigationButtonAnimation()
		NavigationButtonHelper.collapseBackButton();
	}

	handleNavigationButtonAnimation () {
		$('.navigation-button').css({'pointer-events':'none'})
		//ToDo: if current page is not nav, do backwards animation 
		var topBarElement = $('.navigation-button div').get(0);
		var middleBarElement = $('.navigation-button div').get(1);
		var bottomBarElement = $('.navigation-button div').get(2);
		
		$(topBarElement).addClass('navigationButtonTopImageAnimation');
		$(middleBarElement).addClass('navigationButtonMiddleImageForwardsAnimation');
		$(bottomBarElement).addClass('navigationButtonBottomImageAnimation')
		
		setTimeout(function(){
			$(topBarElement).removeClass('navigationButtonTopImageAnimation');
			$(middleBarElement).removeClass('navigationButtonMiddleImageForwardsAnimation');
			$(bottomBarElement).removeClass('navigationButtonBottomImageAnimation')
			$('.navigation-button').css({'pointer-events':'initial'})
		},2000)

	}

	handleBackButtonClick () {
		var onMusicHomePage = window.location.href.includes('dailenspencermusic') && window.location.pathname === "/";

		if ($('.transitioning').length > 0 || onMusicHomePage) {
			return;
		}
		this.props.history.goBack()
		NavigationButtonHelper.collapseBackButton();
	}

	render() {
		return (
			<div className="navigation-button-holder" >
				<div className='navigation-button' onClick={() => this.handleNavigationClick()}>
					<div></div>
					<div></div>
					<div></div>
				</div>
				<div className='back-button' onClick={() => this.handleBackButtonClick()}>
					<div></div>
					<div></div>
				</div>
			</div>
		);
	}
}

export default withRouter(NavigationButton);